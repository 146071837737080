import { AEngine, sleep } from '../core/AEngine.js';
import { APreferenceService, SAVE_IDS } from './APreferenceService.js';
import { AMapOverlayService } from './AMapOverlayService.js';
import { AJsonService } from './AJsonService.js';
import { EVENTS } from './AEventService.js';
import { AError } from '../classes/AError.js';
import { ACrypto } from '../classes/ACrypto.js';
import { AUnitTestService } from './AUnitTestService.js';
import { APopoverService } from './APopoverService.js';
export class AMenuService {
    constructor() {
    }
    async autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        this.preferenceService = AEngine.get(APreferenceService);
        this.startMenuOpened = this.preferenceService.load(SAVE_IDS.MENU_OPEN, null);
        Events.h_once(EVENTS.PREFETCH, _ => this.prefetch());
        this.initHeaderStrip();
    }
    getMenuOptionsForUrl(hash) {
        if (this.menuFlat.hasOwnProperty(hash)) {
            return this.menuFlat[hash];
        }
        if (hash !== '/') {
            AError.handle({
                useModal: false,
                err: [new Error(`Couldn't find the menu item for hash: "${hash}" `)],
            });
        }
        return null;
    }
    initHeaderStrip() {
        $('.header-strip [href]').on('click', e => e.preventDefault());
        $('.header-strip [action="TOGGLE_LANGUAGE"]').on('click', _ => this.toggleLanguageMenu());
    }
    get $content() {
        return $('#AjaxContent');
    }
    get isMenuOpen() {
        const $menu = $('.sidebar-menu');
        return $menu.hasClass('menu-open');
    }
    async fetchMenu() {
        const menu = jsonService.getFromCache({ url: AJsonService.JSON_FILES.MENU });
        for (const menuItem of menu) {
            if (menuItem.premium === true && menuItem.children) {
                menuItem.children.map((child) => child.premium = true);
            }
        }
        return menu;
    }
    toggleLanguageMenu() {
        const $frame = $('#LanguageFrame');
        if ($frame.hasClass('visible')) {
            $frame.removeClass('visible');
        }
        else {
            $('#SearchLanguage').focus();
            $frame.addClass('visible');
        }
    }
    async manualInit() {
        this.menu = await this.fetchMenu();
        window['MenuAjax'] = this.menu;
        this.generateMenuFlat(this.menu);
    }
    async prefetch() {
        const menuHtml = this.generateHtml(this.menu);
        $('.sidebar-menu ul').html(menuHtml);
        $('.header-strip [action="TOGGLE_MENU"]').on('click', e => {
            e.preventDefault();
            this.toggleMenu();
        });
        $('#popover-account [action="PROFILE"]').on('click', e => {
            e.preventDefault();
            window.location.href = '#!/profile.html';
        });
        $('#popover-account [action="SETTINGS"]').on('click', e => {
            e.preventDefault();
            window.location.href = '/settings.html';
        });
        $('#popover-account [action="ADMIN_ALERTS"]').on('click', e => {
            e.preventDefault();
            window.location.href = '#!/aci/admin_alerts.html';
        });
        $('#popover-account [action="SIGNOUT"]').on('click', e => {
            e.preventDefault();
            location.reload();
        });
        $('#popover-account [action="UNIT_TESTING"]').on('click', e => {
            e.preventDefault();
            AEngine.get(AUnitTestService).showDialog();
        });
        $('#popover-account [action="EXPLORE_ERRORS"]').on('click', e => {
            e.preventDefault();
            // TODO: Implementation when central server supports error list
        });
        $('#popover-account [action="NOTIMPL"]').on('click', e => {
            e.preventDefault();
            Alerts.notImplementedYet();
        });
        this.createMouseHandlers().catch(console.error);
        this.restoreSideMenuState().catch(AError.handle);
        AEngine.get(APopoverService).initMenuPopovers();
        this.updateCssContainerSizing();
        // this.createTooltipHandler()
        return this;
    }
    isInitialized() {
        return Object.keys(this.menuFlat).length > 0;
    }
    createTooltipHandler() {
        // TODO: Not Implemented Yet!
        console.warn('NOT IMPLEMENTED YET!');
        const $sidebar = $('.sidebar-menu');
        let $tooltip = this.$tooltip;
        if ($tooltip === undefined) {
            $tooltip = $(`
        <span class="tooltiptext"></span>
      `);
            $('body').append($tooltip);
            this.tooltip = $tooltip;
        }
        $sidebar.find('.menu-links li').mouseenter(e => {
            let left = e.pageX;
            let top = e.pageY;
            $tooltip.css('left', left);
            $tooltip.css('top', top);
            $tooltip.addClass('active');
        });
    }
    setVisible($p, visible) {
        $p.toggleClass('popover-open', visible);
        // if (visible) {
        // $p.addClass('popover-open')
        // } else {
        // $p.removeClass('popover-open')
        // }
    }
    getAllSidebarPopovers() {
        return $('.sidebar-popover[ref]');
    }
    getOrCreatePopover($next) {
        const firstHref = $next.find('[href]').eq(0);
        const href = firstHref.attr('href') || 'none';
        const template = ( /*html*/`
      <div id="sidebar-popover" ref="${href}" class="sidebar-popover">
        <ul>
          <li><a href="#"></a></li>
          <li><a href="#"></a></li>
          <li><a href="#"></a></li>
        </ul>
      </div>
    `);
        const $popover = $(template);
        $('body').append($popover);
        return $popover;
    }
    async restoreSideMenuState() {
        switch (this.startMenuOpened) {
            case null:
                break;
            case true:
                if (!this.isMenuOpen) {
                    this.toggleMenu();
                }
                break;
            case false:
                if (this.isMenuOpen) {
                    this.toggleMenu();
                }
                break;
        }
    }
    async createMouseHandlers() {
        $(document).on('mouseenter', '#sidebar-popover[ref]', (e) => {
            const $popover = $(e.target).is('.sidebar-popover') ? $(e.target) : $(e.target).closest('.sidebar-popover');
            this.setVisible($popover, true);
        });
        $(document).on('mouseleave', '#sidebar-popover[ref]', (e) => {
            const $popover = $(e.target).is('.sidebar-popover') ? $(e.target) : $(e.target).closest('.sidebar-popover');
            this.setVisible($popover, false);
        });
        $(document).on('mouseenter', '#menu-account,#popover-account', (e) => this.setVisible($('#popover-account'), true));
        $(document).on('mouseleave', '#menu-account,#popover-account', (e) => this.setVisible($('#popover-account'), false));
    }
    toggleMenu() {
        const $menu = $('.sidebar-menu');
        const menuClosed = $menu.hasClass('menu-open');
        if (menuClosed) {
            $menu.removeClass('menu-slide-in');
            $menu.removeClass('menu-open');
            this.$content.css('margin-left', 'var(--sidebar-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-width) - var(--sidebar-margin-right))');
        }
        else {
            $menu.addClass('menu-slide-in');
            $menu.addClass('menu-open');
            this.$content.css('margin-left', 'var(--sidebar-open-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-open-width) - var(--sidebar-margin-right))');
        }
        sleep(600).then(_ => Events.tryInvoke(EVENTS.CONTENT_RESIZE, { caller: 'AMenuService' }));
        this.preferenceService.save(SAVE_IDS.MENU_OPEN, this.isMenuOpen);
        return menuClosed;
    }
    updateCssContainerSizing() {
        const $menu = $('.sidebar-menu');
        const menuOpen = $menu.hasClass('menu-open');
        if (menuOpen) {
            this.$content.css('margin-left', 'var(--sidebar-open-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-open-width) - var(--sidebar-margin-right))');
        }
        else {
            this.$content.css('margin-left', 'var(--sidebar-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-width) - var(--sidebar-margin-right))');
        }
    }
    generateHtml(menu) {
        const menuItems = [];
        for (let item of menu) {
            if (item.hidden === true) {
                continue;
            }
            const menuIdMr = item.id_mr ? [item.id_mr] : [];
            if (!permissionService.hasPermission(menuIdMr)) {
                continue;
            }
            const className = item.children ? ` class="menu-dropdown"` : '';
            if (!item.url && item.children && item.children.length) {
                item.url = item.children[0].url;
            }
            const a = item.url ? `<a href="#!${item.url}">${item.title}</a>` : `<a>${item.title}</a>`;
            menuItems.push(`
        <li ${className}>
            <i class="${item.icon}"></i>
            ${a}
            <div class="expand"><i class="fa-solid fa-chevron-right fa-xs"></i></div>
        </li>
      `);
            if (item.children) {
                const childLinks = item.children
                    .filter(c => permissionService.hasPermission(menuIdMr.concat([c.id_mr])))
                    .map(c => `<a href="${c.url}">${c.title}</a>`);
                menuItems.push(`<li ref="menu" class="hidden">${childLinks.join('')}</li>`);
            }
        }
        return menuItems.join('');
    }
    generateMenuFlat(menu) {
        let menuFlat = {};
        $.each(menu, function (_, mv) {
            if (mv.children != undefined) {
                $.each(mv.children, function (_, smv) {
                    menuFlat[smv.url] = Object.assign({}, {
                        // TODO: Check if addition of id_mr fixed the user roles!
                        // TODO: Check with colleagues
                        id_mr: smv.id_mr,
                        title: mv.title + " - " + smv.title,
                        usergroups: smv.usergroups,
                        url: smv.url,
                        parent: mv,
                        obsolete: (smv.obsolete === true),
                        bypass_login: false
                    });
                });
            }
            else {
                menuFlat[mv.url] = Object.assign({}, {
                    id_mr: mv.id_mr,
                    title: mv.title,
                    usergroups: mv.usergroups,
                    url: mv.url,
                    hidden: mv.hidden,
                    bypass_login: mv.bypass_login || false
                });
            }
        });
        this.menuFlat = menuFlat;
    }
    /**
     * @deprecated
     * add menu item to account popover menu
     * @param {{title, href, action, fa, after}} options
     */
    addAccountMenuItem({ title, href, action, fa, after }) {
        // TODO: Check if this method is used
        const $popoverul = $('#popover-account ul');
        if (fa === undefined) {
            fa = 'fa-solid fa-solid fa-siren-on';
        }
        if (after === undefined) {
            after = $popoverul.find('li[uid="profile"]');
        }
        const $menuItem = $(`
      <li uid="${title}">
        <a action="MEMORY">
          <i class="${fa}"></i>
          ${title}
        </a>
      </li>
    `);
        $menuItem.on('click', e => {
            e.preventDefault();
            if (action) {
                action(e);
            }
            else if (href) {
                window.location = href;
            }
            else {
                Alerts.notImplementedYet();
            }
        });
        const $after = after === undefined ? $popoverul.find('li[uid="profile"]') : $popoverul.find(`li[uid="${after}"]`);
        $menuItem.insertAfter($after);
    }
    async addMapButton(options) {
        const { uid, title, icon, order, position, tag } = Object.assign({ uid: ACrypto.randomHexString(10), title: '', tag: 'div' }, options);
        const $ele = $(`
      <${tag} id="${uid}" class="poly-toggle scale-toggle" style="border-radius: 3px; cursor: pointer; text-align: center;">
        <div class="noselect ns-children">
          <label class="form-label">
            ${icon !== undefined ? `<i class="${icon}"></i>` : ''}
            ${title ? await Translate.get(title) : ''}
          </label>
        </div>
      </${tag}>
    `);
        this.mapOverlayService.add($ele, position, { uid, order });
        return $ele;
    }
    async addMapButtonRadio(options) {
        const { titles, order, position } = options;
        if (titles.length === 0)
            return [];
        const $btns = await Promise.all(titles.map((title, i) => this.addMapButton({
            title, position,
            order: (order + i * 0.01),
        })));
        $btns[0].addClass('text-bold');
        $btns.map(($btn, i) => {
            $btn.on('click', () => {
                $btns.map($b => $b.toggleClass('text-bold', $b.is($btn)));
            });
        });
        $btns.map(($btn, i) => {
            let cls = (i !== 0) ?
                ((i !== $btns.length - 1) ? '' : 'map-btn-right') : 'map-btn-left';
            $btn.addClass(`map-btn-inline ${cls}`);
        });
        return $btns;
    }
    async addMapDropdown(arrayOf$ele, options) {
        const { uid, title, icon, order, position } = Object.assign({ uid: ACrypto.randomHexString(10), title: '' }, options);
        const $mapBtn = this.addMapButton({ uid, title, icon, order, position });
        const popoverSelector = `.sidebar-popover[uid="${uid}"]`;
        const $popover = $(`<div id="popover-map" class="sidebar-popover" uid="${uid}"><ul></ul></div>`);
        for (let $ele of arrayOf$ele) {
            const $li = $(`<li></li>`);
            $li.append($ele);
            $popover.find('ul').append($li);
        }
        const $parentWithRelativePos = $('#map');
        $parentWithRelativePos.append($popover);
        $(document).on('mouseenter', `${popoverSelector},#${uid}`, (e) => {
            const $p = $(`${popoverSelector}`), $btn = $(`#${uid}`);
            let mp = ($parentWithRelativePos.offset() || { left: 0, top: 0 });
            let bo = ($btn.offset() || { left: 0, top: 0 });
            $p.css({
                left: bo.left - mp.left,
                top: bo.top - mp.top + ($btn.height() || 0)
            });
            this.setVisible($p, true);
        });
        $(document).on('mouseleave', `${popoverSelector},#${uid}`, (e) => {
            this.setVisible($(`${popoverSelector}`), false);
        });
        Events.on(EVENTS.DESTRUCT, () => {
            $(document).off('mouseenter', `${popoverSelector},#${uid}`);
            $(document).off('mouseleave', `${popoverSelector},#${uid}`);
            $popover.remove();
        });
        return $mapBtn;
    }
}
